<template>
  <section>
    <div class="content-header">
      <h2>Considerations for declaring your positionality</h2>
    </div>
    <div class="content-wrapper">
      <p>Stating your positionality models self-disclosure, perspective taking and empathy among students, to help them consider factors that have informed their own perspectives on an issue, area of study or topic of discussion. </p>
      <p>Positionality statements have emerged out of qualitative research areas, particularly in areas where individuals have engaged in direct observation of behaviour and are interpreting or analyzing it, as often occurs in ethnographic research.</p>
      <p>In many ways, the classroom learning environment (particularly one that fosters rich reflection and learning through discussion) is qualitative by nature, and learning emerges through the co-construction of multiple subjectivities and narratives that emerge from interactions between the students and instructor. </p>
      <p>To promote critical analyses and self-awareness, it is imperative that we as instructors reflect on our own subjectivities—our identities and lived experiences—that have influenced our worldview, and to encourage students to do the same.</p>
      <p>Sharing positionality can feel vulnerable, and instructors should consider levels of self-disclosure based on their own comfort, the relevance to the course content and their desire for building an engaged classroom community where students are both comfortable and encouraged to critically reflect on themselves, their identities and their learning.</p>
      <p class="lm">Learn more</p>
      <p>There are great resources on declaring positionality in qualitative research:</p>
      <ul>
        <li class="pb-2"><a href="https://www.youtube.com/watch?v=fTHFud7fr8c" target="_blank">"Positionality & Research: How our Identities Shape Inquiry"</a> (video)</li>
        <li><a href="https://files.eric.ed.gov/fulltext/EJ1268044.pdf" target="_blank">Researcher Positionality - A Consideration of Its Influence and Place in Qualitative Research - A New Researcher Guide </a>(journal article). Note that this work is licensed under a <a href="https://creativecommons.org/licenses/by-sa/4.0/" target="_blank">Creative Commons Attribution-ShareAlike 4.0 International License</a>.</li>
      </ul>

    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
